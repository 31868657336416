<template>
	<ion-menu content-id="main-content" type="overlay">
		<ion-content id="container">
			<Account />
			<ion-list id="inbox-list">
				<ion-menu-toggle :auto-hide="false" v-for="(p, i) in appPages" :key="i">
					<ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
						<ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
						<ion-label>{{ p.title }}</ion-label>
					</ion-item>
				</ion-menu-toggle>
			</ion-list>

			<ion-list id="info-list">
				<ion-menu-toggle :auto-hide="false" v-for="(p, i) in appSubPages" :key="i">
					<ion-item @click="selectedIndex = i + appPages.length" router-direction="root" :router-link="p.url" lines="none" :detail="false" class="hydrated" :class="{ selected: selectedIndex === i + appPages.length }">
						<!--<ion-icon aria-hidden="true" slot="start" :ios="informationCircle" :md="informationCircle"></ion-icon>-->
						<ion-label>{{ p.title }}</ion-label>
					</ion-item>
				</ion-menu-toggle>
			</ion-list>
		</ion-content>
	</ion-menu>
</template>

<script setup>

import Account from '@/views/components/Auth/Account.vue';

import { shallowRef } from 'vue';

import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
} from '@ionic/vue';

import {
	homeOutline,
	homeSharp,
	calendar,
	compass
} from 'ionicons/icons';

import { useRoute } from 'vue-router';

// selected index indicator
const selectedIndex = shallowRef(0);

// nav menu links
const appPages = [
	{
		title: '通書 ThongShu',
		url: '/thong-shu',
		iosIcon: calendar,
		mdIcon: calendar,
	},
	/*{
		title: '羅盤 LuoPan',
		url: '/luo-pan',
		iosIcon: compass,
		mdIcon: compass,
	},*/
	{
		title: '奇門遁甲 QMDJ',
		url: '/qimen',
		iosIcon: compass,
		mdIcon: compass,
	},
];

const appSubPages = [
	{
		title: 'Privacy Policy',
		url: '/privacy-policy',
	},
	{
		title: 'Terms & Conditions',
		url: '/terms-conditions',
	}
];

const socialPages = [
	{
		url: '/home',
		iosIcon: homeOutline,
		mdIcon: homeSharp,
	},
]

const route = useRoute();

console.log();
if (route.path !== undefined) {
	selectedIndex.value = appPages.findIndex((page) => page.url.toLowerCase() === route.path);
}

</script>

<style scoped>
ion-menu ion-content {
	--background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
	--padding-start: 8px;
	--padding-end: 8px;
	--padding-top: 20px;
	--padding-bottom: 20px;
}

ion-menu.md ion-list {
	padding: 20px 0;
}

ion-menu.md ion-note {
	margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
	padding-left: 10px;
}

/*ion-menu.md ion-list#inbox-list {
	border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}*/

ion-menu.md ion-list#inbox-list ion-list-header {
	font-size: 22px;
	font-weight: 600;

	min-height: 20px;
}

ion-menu.md ion-item {
	--padding-start: 10px;
	--padding-end: 10px;
	border-radius: 4px;
}

ion-menu.md ion-item.selected {
	--background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
	color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
	color: #616e7e;
}

ion-menu.md ion-item ion-label {
	font-weight: 500;
}

ion-menu.ios ion-content {
	--padding-bottom: 20px;
}

ion-menu.ios ion-list {
	padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
	line-height: 24px;
	margin-bottom: 20px;
}

ion-menu.ios ion-item {
	--padding-start: 16px;
	--padding-end: 16px;
	--min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
	color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
	font-size: 24px;
	color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
	margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
	padding-left: 16px;
	padding-right: 16px;
}

ion-menu.ios ion-note {
	margin-bottom: 8px;
}

ion-note {
	display: inline-block;
	font-size: 16px;

	color: var(--ion-color-medium-shade);
}

ion-item.selected {
	--color: var(--ion-color-primary);
}

#container {
	position: relative;
}
#inbox-list {
	align-items: flex-start;
	max-height: 50%;
}
#info-list {
	border-top: 1px solid var(--ion-color-step-150, #d7d8da);
	position: absolute;
	bottom: 0;
	width: 95%;
}

#info-list ion-label {
	font-size: 0.74em;
}
</style>