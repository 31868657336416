<template>
  <ion-app>
      <sidebar-nav-menu-nav-menu />
      <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
</template>

<script setup>
import {
	IonApp,
	IonRouterOutlet, 
	useIonRouter,
} from '@ionic/vue';
import { useRoute } from 'vue-router';
import SidebarNavMenuNavMenu from './views/components/SidebarNavMenu.vue';

import { supabase } from './supabase';

const router = useIonRouter();
const route = useRoute();

/*supabase.auth.onAuthStateChange((_, session) => {
	if (!session?.user) {
		router.push('/login');
	} else if (session?.user && route.name === 'login') {
		router.push('/');
	}
})*/

/*onMounted(() => {
	supabase.auth.onAuthStateChange((event, session) => {
		if (!session?.user) {
			router.replace('/login');
		} else {
			if(window.location.pathname === '/login') {
				router.replace('/home');
			}
			
		}

		if (event == 'PASSWORD_RECOVERY') console.log('PASSWORD_RECOVERY', session)
		if (event == 'SIGNED_IN') console.log('SIGNED_IN', session)
		if (event == 'SIGNED_OUT') console.log('SIGNED_OUT', session)
		if (event == 'TOKEN_REFRESHED') console.log('TOKEN_REFRESHED', session)
		if (event == 'USER_UPDATED') console.log('USER_UPDATED', session)
	})
})*/
</script>
