<template>
	<!--<ion-list id="account-list">
		<ion-list-header>
		  {{ acct.name }}
		</ion-list-header>
		<ion-note>
		  <template v-if="acct.email">
			{{ acct.email }} <a href="#" @click="signOut">[logout]</a>
		  </template>
		  <template v-else>
			<a @click="signIn" v-if="$route.name !== 'Login'">[login]</a>
		  </template>
		</ion-note>
	</ion-list>-->
	<div :style="{display:'flex',justifyContent:'center',alignItems:'center'}">
		<a href="https://www.facebook.com/fengshuiexpose" target="_blank">
			<ion-avatar>
				<img alt="Lumen Metaphysics" :src="logo" />
			</ion-avatar>
		</a>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import {
	IonList,
	IonListHeader,
	IonNote,
	toastController,
	loadingController,
	menuController,
	IonAvatar
} from '@ionic/vue';
import { computed, reactive } from 'vue';
import { supabase } from '@/supabase';
import { useAuthStore } from '@/stores/User';
import logo from '@/assets/images/logo.png';

const store = useAuthStore();

const acct = computed(() => {
	const user = store.isLoggedIn ? store.user : {
		email: null,
		name: 'anonymous'
	}
	return user;
})

const router = useRouter();

const signIn = async () => {
	await menuController.close();
	router.push('/login');	
}

const signOut = async () => {
	/*const loader = await loadingController.create({});
	const toast = await toastController.create({ duration: 2000 });
	await loader.present();
	try {
		toast.message = 'Logging Out';
		const { error } = await supabase.auth.signOut();
		console.log(await Preferences.get({ key: 'auth'}));
		await store.deleteUser();
		console.log(await Preferences.get({ key: 'auth'}));
		if (error) throw error
	} catch (error) {
		toast.message = error.message
		await toast.present();
	} finally {
		//await menuController.close();
		await loader.dismiss();
		await toast.dismiss();
		//router.push('/');
	}*/
}

</script>

<style scoped>

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#account-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#account-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  width: 100%;
  text-align: left;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>