import { defineStore } from 'pinia';
import { Preferences } from '@capacitor/preferences';

const user = {
	avatar_url: '',
	email: '',
	email_verified: '',
	full_name: '',
	iss: '',
	name: '',
	phone_verified: '',
	picture: '',
	provider_id: '',
	sub: '',
};

const session = {
	asscess_token: '',
	refresh_token: '',
	token_type: '',
	expires_at: '',
	expires_in: '',
}


export const useAuthStore = () => {
	const innerStore = defineStore('auth', {
		state() {
			return { 
				user : user,
				session: session
			}
		},
		getters: {
			isLoggedIn() {
				return this.user.email.length > 0;
			},
		},
		actions: {
			async saveUser(user, session) {
				await Preferences.set({
					key: 'auth',
					value: JSON.stringify({user: user, session: session})
				});
			},
			async deleteUser() {
				await Preferences.remove({ key: 'auth' });
			},
			async getLocalUser() {
				try {
					const { value } = await Preferences.get({ key: 'auth' });
					const auth = JSON.parse(value || '');
					this.user = auth.user;
					this.session = auth.session;
					return user;
				} catch (e) {
				//
				}
			},
			async setUser(payload) {
				const resp = payload;
				delete resp.session.user;
				const session = resp.session;
				const user = resp.user.user_metadata;
				await this.saveUser(user, session);
			},
			async resetUser() {
				await this.deleteUser();
				this.user = getEmptyUser();				
			},
		}
	  
	});
	const s = innerStore();
	if (s.user.email.length < 1) {
		s.getLocalUser();
	}
	return s;
}