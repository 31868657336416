import { App } from '@capacitor/app';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { supabase } from '@/supabase';
import { useAuthStore } from '@/stores/User';

const routes = [
	{
		path: '',
		name: 'Home',
		redirect: '/thong-shu'
	},
	/*{
		path: '',
		name: 'Home',
		component: () => import ('../views/pages/HomePage.vue'),
	},*/
	{
		path: '/luo-pan',
		name: 'Luo Pan',
		component: () => import ('../views/pages/LuoPanPage.vue'),
	},
	{
		path: '/thong-shu',
		name: 'Thong Shu',
		component: () => import ('../views/pages/ThongShuPage.vue'),
	},
	{
		path: '/qimen',
		name: 'Qimen',
		component: () => import ('../views/pages/QimenPage.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import ('../views/pages/LoginPage.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'Privacy Policy',
		component: () => import ('../views/pages/PrivacyPage.vue'),
	},
	{
		path: '/terms-conditions',
		name: 'Terms & Conditions',
		component: () => import ('../views/pages/TermPage.vue'),
	},
	/*{
		path: '/oauth-callback',
		name: 'Oauth Callback',
		component: () => import ('../views/pages/OauthCallbackPage.vue'),
	}*/
	/*{
		path: '/account',
		name: 'Account',
		component: () => import ('../views/pages/AccountPage.vue'),
	},
	{
		path: '/folder/:id',
		component: () => import ('../views/pages/FolderPage.vue')
	},*/
]


const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	const store = useAuthStore();
	const isAuthenticated = store.user.email.length > 0 ? true : false;
	//const { data: { session }} = await supabase.auth.getSession();
	//const isAuthenticated = session?.user ? true : false;
	if (to.name === 'Login' && isAuthenticated) { 
		next({ name: 'Home' });
		//next({ name: 'Login' })
	}
	//else if(to.name !== 'Privacy Policy' || to.name !== 'Terms & Conditions' || to.name !== 'Oauth Callback')  {
		//next();
	//}
	else {
		next();
	}
})

router.afterEach(async () => {

})

/*App.addListener('appUrlOpen', async (data) => {
	const url = new URL(data.url);
	const hashParams = new URLSearchParams(url.hash.substring(1));

	const access_token = hashParams.get('access_token');
	const refresh_token = hashParams.get('refresh_token');
	const expires_in = hashParams.get('expires_in');
	const token_type = hashParams.get('token_type');
	
	if (access_token && refresh_token && expires_in && token_type) {
		const { data: session, error } = await supabase.auth.setSession({
		access_token,
		refresh_token,
		expires_in,
		token_type
		});
	
		if (error) {
		console.error('Error during setting session:', error);
		} else {
		console.log('Session:', session);
		// Handle user and session storage here
		}
	}
});*/

App.addListener('appUrlOpen', function (event) {
	// Example url: https://beerswift.app/tabs/tabs2
	// slug = /tabs/tabs2
	const slug = event.url.split('.app').pop();

	// We only push to the route if there is a slug present
	if (slug) {
		router.push({
		path: slug,
		});
	}
});

export default router
